<template>
    <main id="js-page-content" class="page-content" role="main" v-if='loading'>
        <div class="subheader hidden-lg-up">
            <portal to="ariane">
                <MultipleListCreate></MultipleListCreate>
            </portal>
        </div>

        <dl class="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3"  v-if='endpointStatistics'>
            <div v-if="endpointStatistics.totalMessages"
                class="relative bg-white py-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden"
            >
                <dt>
                    <div class="absolute bg-blue-500 rounded-md p-3">
                        <IconChat class="h-6 w-6 text-white" />
                    </div>
                    <p class="ml-16 text-sm font-medium text-gray-700">
                        {{ $t('post.page.performances.answers_total') }}
                    </p>
                </dt>
                <dd class="ml-16 flex items-baseline" >
                    <p class="text-2xl font-semibold text-gray-900">
                        {{ endpointStatistics.totalMessages }}
                    </p>
                </dd>
            </div>

            <div v-if="endpointStatistics.totalPostWithMessage"
                class="relative bg-white py-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden"
            >
                <dt>
                    <div class="absolute bg-blue-500 rounded-md p-3">
                        <IconChat class="h-6 w-6 text-white" />
                    </div>
                    <p class="ml-16 text-sm font-medium text-gray-700">
                        {{ $t('post.page.performances.answers_total_tickets') }}
                    </p>
                </dt>
                <dd class="ml-16 flex items-baseline" >
                    <p class="text-2xl font-semibold text-gray-900">
                        {{ endpointStatistics.totalPostWithMessage }}
                    </p>
                </dd>
            </div>

            <div v-if="endpointStatistics.totalFinishedPostsWithoutAnswer"
                class="relative bg-white py-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden"
            >
                <dt>
                    <div class="absolute bg-blue-500 rounded-md p-3">
                        <IconCheck class="h-6 w-6 text-white" />
                    </div>
                    <p class="ml-16 text-sm font-medium text-gray-700">
                        {{
                            $t('post.page.performances.no_answer_total_tickets')
                        }}
                    </p>
                </dt>
                <dd class="ml-16 flex items-baseline" >
                    <p class="text-2xl font-semibold text-gray-900">
                        {{ endpointStatistics.totalFinishedPostsWithoutAnswer }}
                    </p>
                </dd>
            </div>

            <div  v-if="endpointStatistics.averageResponseTime"
                class="relative bg-white py-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden"
            >
                <dt>
                    <div class="absolute bg-blue-500 rounded-md p-3">
                        <IconChat class="h-6 w-6 text-white" />
                    </div>
                    <p class="ml-16 text-sm font-medium text-gray-700">
                        {{
                            $t(
                                'post.page.performances.answers_average_by_ticket'
                            )
                        }}<br />
                        <span class="text-gray-500 text-xs mt-1">{{
                            $t('post.page.performances.at_least_one_answer')
                        }}</span>
                    </p>
                </dt>
                <dd class="ml-16 flex items-baseline">
                    <p class="text-2xl font-semibold text-gray-900">
                        {{ endpointStatistics.averageResponseTime }}
                    </p>
                </dd>
            </div>

            <div v-if="endpointStatistics.averageMessagePerPost"
                class="relative bg-white py-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden"
            >
                <dt>
                    <div class="absolute bg-blue-500 rounded-md p-3">
                        <IconChat class="h-6 w-6 text-white" />
                    </div>
                    <p class="ml-16 text-sm font-medium text-gray-700">
                        {{
                            $t(
                                'post.page.performances.answers_average_required'
                            )
                        }}
                    </p>
                </dt>
                <dd class="ml-16 flex items-baseline" >
                    <p class="text-2xl font-semibold text-gray-900">
                        {{ endpointStatistics.averageMessagePerPost }}
                    </p>
                </dd>
            </div>

            <div v-if="endpointStatistics.averageMessagePerPostFinished"
                class="relative bg-white py-5 px-4 sm:py-6 sm:px-6 shadow rounded-lg overflow-hidden"
            >
                <dt>
                    <div class="absolute bg-blue-500 rounded-md p-3">
                        <IconChat class="h-6 w-6 text-white" />
                    </div>
                    <p class="ml-16 text-sm font-medium text-gray-700">
                        {{
                            $t(
                                'post.page.performances.answers_average_required'
                            )
                        }}<br />
                        <span class="text-gray-500 text-xs mt-1">{{
                            $t('post.page.performances.at_least_one_answer')
                        }}</span>
                    </p>
                </dt>
                <dd class="ml-16 flex items-baseline">
                    <p class="text-2xl font-semibold text-gray-900">
                        {{ endpointStatistics.averageMessagePerPostFinished }}
                    </p>
                </dd>
            </div>
        </dl>

        <div class="mt-5 gap-5 grid grid-cols-1 md:grid-cols-2 items-stretch" v-if='barChartData || barChartData2'>
            <BarChart v-if='barChartData'
                :titleChart="
                    $t('post.page.performances.charts.delay_first_answer.title')
                "
                :headingChart="
                    $t(
                        'post.page.performances.charts.delay_first_answer.heading'
                    )
                "
                chartId="barChartId"
                :chartData="barChartData"
            />
            <BarChart v-if='barChartData2'
                :titleChart="
                    $t(
                        'post.page.performances.charts.delay_submission_resolution.title'
                    )
                "
                :headingChart="
                    $t(
                        'post.page.performances.charts.delay_submission_resolution.heading'
                    )
                "
                chartId="barChartId2"
                :chartData="barChartData2"
            />
        </div>

        <div class="mt-5 gap-5 grid grid-cols-1 md:grid-cols-3 items-stretch" v-if='barChartData2'>
            <BarChart
                :titleChart="
                    $t('post.page.performances.charts.answer_by_ticket.title')
                "
                headingChart=""
                chartId="barChartId3"
                :chartData="barChartData2"
            />
            <BarChart
                :titleChart="
                    $t('post.page.performances.charts.answer_by_ticket.title')
                "
                :headingChart="
                    $t(
                        'post.page.performances.charts.answer_by_ticket.heading.resolved'
                    )
                "
                chartId="barChartId4"
                :chartData="barChartData2"
            />
            <BarChart
                :titleChart="
                    $t('post.page.performances.charts.answer_by_ticket.title')
                "
                :headingChart="
                    $t(
                        'post.page.performances.charts.answer_by_ticket.heading.at_least_one_answer'
                    )
                "
                chartId="barChartId5"
                :chartData="barChartData2"
            />
        </div>
    </main>
</template>

<script>
// import Panel from '../../components/UI/Panel'
import { mapActions, mapState } from 'vuex'
import MultipleListCreate from '@/components/UI/MultipleListCreate'
import BarChart from '@/components/UI/BarChart'
import IconCheck from '@/components/icons/iconCheck.vue'
// import IconNotification from '@/components/icons/iconNotification.vue'
import IconChat from '@/components/icons/iconChat.vue'

export default {
    name: 'Performances',
    components: {
        // Panel,
        MultipleListCreate,
        BarChart,
        IconCheck,
        // IconNotification,
        IconChat
    },
    data() {
        return {
          loading : false,
            endpointStatistics: null,
            barChartData: {
                type: 'bar',
                data: {
                    labels: ['1h', '2h', '3h', '4h', '5h', '6h', '7h'],
                    datasets: [
                        {
                            data: [25, 55, 100, 75, 60, 60, 120, 150, 40, 0],
                            fill: false,
                            backgroundColor: '#3B82F6'
                        }
                    ]
                },
                options: {
                    legend: {
                        position: false
                    },
                    plugins: {
                        datalabels: {
                            color: '#FFFFFF',
                            font: {
                                weight: 'bold',
                                size: 16
                            }
                        }
                    }
                }
            },
            barChartData2: {
                type: 'bar',
                data: {
                    labels: ['1h', '2h', '3h', '4h', '5h', '6h', '7h'],
                    datasets: [
                        {
                            data: [23, 4, 45, 6, 33, 45, 12, 20, 40, 0],
                            fill: false,
                            backgroundColor: '#10B981'
                        }
                    ]
                },
                options: {
                    legend: {
                        position: false
                    },
                    plugins: {
                        datalabels: {
                            color: '#FFFFFF',
                            font: {
                                weight: 'bold',
                                size: 16
                            }
                        }
                    }
                }
            }
        }
    },
    computed: {
        ...mapState('post', {
            config: (state) => state.postsConfig
            // statistics: (state) => state.statistics,
        })
    },
    created() {
      this.loading = false;
        this.getPerformance()
            .then((value) => {
              this.loading = true;
                this.endpointStatistics = value.data
            })
            .catch(
                // requete rejetée
                function () {
                    console.log('req rompue')
                }
            )
    },
    methods: {
        ...mapActions('post', ['getPerformance'])
    }
}
</script>
